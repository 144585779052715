$meter-background-color: #efefef;
$meter-background-even-less-color: #D14900;
$meter-background-suboptimum-color: #F5D000;
$meter-background-optimum-color: #3C5C00;
$meter-border-radius: 0 0 10px 10px;

.tx_evoweb_sfregister {
    iframe#bargraph {
        display: none;
        height: 14px;
        width: 372px;
        border: none;

        &.show {
            display: block;
        }
    }

    meter#bargraph {
        display: none;
        background: $meter-background-color;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: $meter-border-radius;
        height: 11px;
        width: 100%;

        &.show {
            display: block;
        }

        /* Polyfill */
        .meter-inner-element {
            appearance: inherit;
            box-sizing: inherit;
            height: 100%;
            width: 100%;
            background: $meter-background-color;
            border: none;
            border-radius: $meter-border-radius;
        }

        .meter-bar {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background: $meter-background-color;
            border: none;
            border-radius: $meter-border-radius;
        }

        .meter-optimum-value,
        .meter-suboptimum-value,
        .meter-even-less-good-value {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            user-modify: read-only;
            border-radius: $meter-border-radius;
        }

        .meter-optimum-value {
            background: $meter-background-optimum-color;
        }

        .meter-suboptimum-value {
            background: $meter-background-suboptimum-color;
        }

        .meter-even-less-good-value {
            background: $meter-background-even-less-color;
        }

        /* WebKit */
        &::-webkit-meter-inner-element {
            background: $meter-background-color;
            border: none;
            border-radius: $meter-border-radius;
        }

        &::-webkit-meter-bar {
            background: $meter-background-color;
            border: 1px solid #ccc;
            border-top: none;
            border-radius: $meter-border-radius;
        }

        &::-webkit-meter-optimum-value,
        &::-webkit-meter-suboptimum-value,
        &::-webkit-meter-even-less-good-value {
            border-radius: $meter-border-radius;
        }

        &::-webkit-meter-optimum-value {
            background: $meter-background-optimum-color;
        }

        &::-webkit-meter-suboptimum-value {
            background: $meter-background-suboptimum-color;
        }

        &::-webkit-meter-even-less-good-value {
            background: $meter-background-even-less-color;
        }

        /* Firefox */
        &::-moz-meter-inner-element {
            background: $meter-background-color;
        }

        &::-moz-meter-bar {
            background: $meter-background-color;
            border: none;
            border-radius: $meter-border-radius;
        }

        &:-moz-meter-optimum::-moz-meter-bar ,
        &:-moz-meter-sub-optimum::-moz-meter-bar,
        &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
            border-radius: $meter-border-radius;
        }

        &:-moz-meter-optimum::-moz-meter-bar {
            background: $meter-background-optimum-color;
        }

        &:-moz-meter-sub-optimum::-moz-meter-bar {
            background: $meter-background-suboptimum-color;
        }

        &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
            background: $meter-background-even-less-color;
        }
    }

    #sfrZone_loading {
        position: relative;
        top: -35px;
        left: -13px;
        margin-left: 50%;
    }

    .grecaptcha-badge {
        margin-right: -10000px;
    }
}
